define("discourse/plugins/discourse-locations/discourse/components/custom-wizard-field-location", ["exports", "@ember/component", "@glimmer/component", "I18n", "@ember/object", "@ember/service", "@glimmer/tracking", "@ember/template-factory"], function (_exports, _component, _component2, _I18n, _object, _service, _tracking, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <LocationForm
    @street={{this.street}}
    @neighbourhood={{this.neighbourhood}}
    @postalcode={{this.postalcode}}
    @city={{this.city}}
    @state={{this.state}}
    @countrycode={{this.countrycode}}
    @geoLocation={{this.geoLocation}}
    @rawLocation={{this.rawLocation}}
    @inputFields={{this.inputFields}}
    @searchOnInit={{this.searchOnInit}}
    @setGeoLocation={{this.setGeoLocation}}
    @searchError={{this.searchError}}
  />
  */
  {
    "id": "TraK9uY5",
    "block": "[[[8,[39,0],null,[[\"@street\",\"@neighbourhood\",\"@postalcode\",\"@city\",\"@state\",\"@countrycode\",\"@geoLocation\",\"@rawLocation\",\"@inputFields\",\"@searchOnInit\",\"@setGeoLocation\",\"@searchError\"],[[30,0,[\"street\"]],[30,0,[\"neighbourhood\"]],[30,0,[\"postalcode\"]],[30,0,[\"city\"]],[30,0,[\"state\"]],[30,0,[\"countrycode\"]],[30,0,[\"geoLocation\"]],[30,0,[\"rawLocation\"]],[30,0,[\"inputFields\"]],[30,0,[\"searchOnInit\"]],[30,0,[\"setGeoLocation\"]],[30,0,[\"searchError\"]]]],null]],[],false,[\"location-form\"]]",
    "moduleName": "discourse/plugins/discourse-locations/discourse/components/custom-wizard-field-location.hbs",
    "isStrictMode": false
  });
  class CustomWizardFieldLocationComponent extends _component2.default {
    static #_ = (() => dt7948.g(this.prototype, "siteSettings", [_service.inject]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "name", [_tracking.tracked], function () {
      return null;
    }))();
    #name = (() => (dt7948.i(this, "name"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "street", [_tracking.tracked], function () {
      return null;
    }))();
    #street = (() => (dt7948.i(this, "street"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "postalcode", [_tracking.tracked], function () {
      return null;
    }))();
    #postalcode = (() => (dt7948.i(this, "postalcode"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "city", [_tracking.tracked], function () {
      return null;
    }))();
    #city = (() => (dt7948.i(this, "city"), void 0))();
    static #_6 = (() => dt7948.g(this.prototype, "countrycode", [_tracking.tracked], function () {
      return null;
    }))();
    #countrycode = (() => (dt7948.i(this, "countrycode"), void 0))();
    static #_7 = (() => dt7948.g(this.prototype, "geoLocation", [_tracking.tracked], function () {
      return {
        lat: "",
        lon: ""
      };
    }))();
    #geoLocation = (() => (dt7948.i(this, "geoLocation"), void 0))();
    static #_8 = (() => dt7948.g(this.prototype, "rawLocation", [_tracking.tracked], function () {
      return null;
    }))();
    #rawLocation = (() => (dt7948.i(this, "rawLocation"), void 0))();
    context = (() => this.args.wizard.id)();
    includeGeoLocation = true;
    inputFieldsEnabled = true;
    layoutName = "javascripts/wizard/templates/components/wizard-field-location";
    showType = true;
    constructor() {
      super(...arguments);
      const existing = this.args.field.value || {};
      const inputFields = this.inputFields;
      inputFields.forEach(f => {
        if (existing[f]) {
          this[f] = existing[f];
        }
      });
      this.geoLocation = existing["geo_location"] || {};
      this.args.field.customCheck = this.customCheck.bind(this);
    }
    customCheck() {
      const required = this.required;
      const hasInput = this.inputFields.some(f => this[f]);
      if (required || hasInput) {
        return this.handleValidation();
      } else {
        return true;
      }
    }
    get inputFields() {
      return this.siteSettings.location_input_fields.split("|");
    }
    static #_9 = (() => dt7948.n(this.prototype, "inputFields", [_object.computed]))();
    handleValidation() {
      let location = {};
      if (this.inputFieldsEnabled && this.inputFields.indexOf("coordinates") > -1 && (this.geoLocation.lat || this.geoLocation.lon)) {
        return this.setValidation(this.geoLocation.lat && this.geoLocation.lon, "coordinates");
      }
      if (this.inputFieldsEnabled) {
        let validationType = null;
        this.inputFields.some(field => {
          const input = this[`${field}`];
          if (!input || input.length < 2) {
            validationType = field;
            return true;
          } else {
            location[field] = input;
          }
        });
        if (validationType) {
          return this.setValidation(false, validationType);
        }
      }
      if (this.includeGeoLocation) {
        let valid = this.geoLocation && this.geoLocation.lat && this.geoLocation.lon;
        let message;
        if (valid) {
          location["geo_location"] = this.geoLocation;
          this.args.field.value = location;
        } else {
          message = "geo_location";
        }
        return this.setValidation(valid, message);
      } else {
        this.args.field.value = location;
        return this.setValidation(true);
      }
    }
    setValidation(valid, type) {
      const message = type ? _I18n.default.t(`location.validation.${type}`) : "";
      this.args.field.setValid(valid, message);
      return valid;
    }
    setGeoLocation(gl) {
      this.name = gl.name;
      this.street = gl.street;
      this.neighbourhood = gl.neighbourhood;
      this.postalcode = gl.postalcode;
      this.city = gl.city;
      this.state = gl.state;
      this.geoLocation = {
        lat: gl.lat,
        lon: gl.lon
      };
      this.countrycode = gl.countrycode;
      this.rawLocation = gl.address;
    }
    static #_10 = (() => dt7948.n(this.prototype, "setGeoLocation", [_object.action]))();
    searchError(error) {
      this.flash = error;
    }
    static #_11 = (() => dt7948.n(this.prototype, "searchError", [_object.action]))();
  }
  _exports.default = CustomWizardFieldLocationComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, CustomWizardFieldLocationComponent);
});